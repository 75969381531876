import React from "react";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Pricing from "./pages/pricing";
import { initializeAOS } from "../src/Utils/custom";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    initializeAOS();
  }, []);



  return (
    <>

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
