import React from "react";
import SliderLaptop from "../components/SliderLaptop";

export default function Banner() {
  return (
    <>
      <section className="banner">
        <picture>
          <img
        
            className="banner-glow-top animate-top-light"
            src="assets/front/images/banner-glow-top.svg"
            alt="images"
          />
        </picture>
        <div className="container">
          <div className="banner-content">
            <div className="heading-content">
              <h1 className="banner-heading">A fast market needs even</h1>

              <div className="two-parts">
                <h2 className="sentence">
                  <div className="slidingVertical">
                    <span>Better</span>
                    <span>Faster</span>
                    <span>Smarter</span>
                  </div>
                </h2>
                <h1 className="banner-heading b-2">analytics</h1>
              </div>
            </div>

            <p className="main-para">
              Ticketmetric collects data from over 120,000 events across <br />{" "}
              Ticketmaster, AXS, and StubHub markets to help ticket <br />{" "}
              brokers flip
              <span> better, faster, and smarter.</span>
            </p>

            <div className="btn-banner">
              <a href="/" className="btn-main">
                Try For Free
              </a>

              <span>No Credit card required</span>
            </div>
          </div>
        </div>

        <div className="laptop-scene">

            <img
        
              className="stand"
              src="assets/front/images/stand.svg"
              alt="images"
            />


          <div className="laptop-lottie">
            <SliderLaptop />
          </div>
     
            <img
    
              className="shadow-image"
              src="assets/front/images/laptop-shadow.webp"
              alt="images"
            />
 
        </div>

          <img
          
            className="banner-glow-bottom animate-bottom-light"
            src="assets/front/images/banner-glow-bottom.svg"
            alt="images"
          />


        <div className="clip-path"></div>
      </section>
    </>
  );
}
