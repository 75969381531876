import React from "react";

export default function PriceBanner() {
  return (
    <>
      <section className="inner-banner" data-aos="fade-in">
        <img
          className="banner-glow-top animate-top-light"
          src="assets/front/images/banner-glow-top.png"
          alt=""
        />

        <div className="container b-content">
          <div className="row">
            <div
              className="col-lg-6 col-sm-12 b-left"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img
                className="logoBeta"
                src="assets/front/images/pricing/logo1.svg"
                alt=""
              />
              <h1>Try our FREE beta plan</h1>
              <div className="btn-banner">
                <a href="#" className="btn-main">
                  Try For Free
                </a>

                <span>No Credit card required</span>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 b-right p-0">
              <div
                className="b-list"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <span>
                  <img src="assets/front/images/pricing/check.png" alt="" />
                  All Sold Data
                </span>
                <span>
                  <img src="assets/front/images/pricing/check.png" alt="" />
                  All Analytics
                </span>
              </div>
              <div
                className="b-list m-0"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <span>
                  <img src="assets/front/images/pricing/check.png" alt="" />
                  Full Extension
                </span>
                <span>
                  <img src="assets/front/images/pricing/check.png" alt="" />
                  20 Alerts Actions
                </span>
              </div>
            </div>
          </div>
        </div>

        <img
          className="banner-glow-bottom animate-bottom-light"
          src="assets/front/images/banner-glow-bottom.png"
          alt=""
        />
      </section>
    </>
  );
}
