import React from "react";
import { useEffect } from "react";
import { clickMenu, clickMenulist } from "../Utils/custom";
import { Link } from "react-router-dom";

export default function Navbar() {

  useEffect(() => {
    clickMenu();
    clickMenulist();
  }, []);

  return (
    <>
      <header className="main-header">
        <nav className="navbar navbar-expand-md  custom-nav">
          <div className="container">
          <Link to="/"
                className="navbar-brand order-first order-md-0 mx-0">
                   <picture>
                  <img
                    className="logo-header"
                    src="assets/front/images/logo-header.svg"
                    alt="logo"
                  />
                </picture>
                </Link>
            <div
              className="nav-contact"
             
            >
              <ul className="nav navbar-nav w-100 nav-main">
                <li className="nav-item">
                  <a className="nav-link" id="software" href="/#features">
                    Software
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="toolsExtension" href="/#features">
                    Tools & Extension
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="artistAnalytics"
                    href="/#features"
                  >
                    Artist Analytics
                  </a>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/pricing">Pricing</Link>
                
                </li>
              </ul>
            </div>
            <div
              className="justify-content-end nav-contact"
              
            >
              <ul className="nav navbar-nav w-100 nav-main">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Sign Up
                  </a>
                </li>
                <li className="nav-item mr-0">
                  <a className="nav-link" href="/">
                    Login
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <header className="mobile-header">
        <div className="container p-0">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid p-0">

            <Link to="/"  
                className="navbar-brand order-first order-md-0 mx-0">
                   <picture>
                  <img
                    className="logo-header"
                    src="assets/front/images/Logo-colored.svg"
                    alt="logo"
                  />
                </picture>
                </Link>
     

              

              <div className="justify-content-end">
                <button className="mobile-nav-btn" id="mobile-nav-btn">
                  <i className="fa-solid fa-bars" id="open"></i>
                  <i className="fa-solid fa-xmark" id="close"></i>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <ul className="menu-wrap" id="menu-wrap">
        <li className="nav-item">
          <a className="nav-link mob-link" id="mob-software" href="/#features">
            Software
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link mob-link" id="mob-toolsExtension" href="/#features">
            Tools & Extension
          </a>
        </li>

        <li className="nav-item">
          <a className="nav-link mob-link" id="mob-artistAnalytics" href="/#features">
            Artist Analytics
          </a>
        </li>

        <li className="nav-item">
                  <Link className="nav-link mob-link" to="/pricing">Pricing</Link>
        </li>
        <li className="nav-item">
          <a className="nav-link mob-link" href="/">
            Sign Up
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link mob-link" href="/">
            Login
          </a>
        </li>
      </ul>
    </>
  );
}
