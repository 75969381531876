import React from "react";

export default function Calltoaction() {
  return (
    <>
      <section className="cta">
        <div className="container" >
          {/* <h2 className="heading">
            Ticketmetric is the future of ticket reselling. Access the <br />
            essential data you need to master flips in both primary and
            <br /> secondary markets—because Ticketmetric is flipping
            <br /> awesome!
          </h2> */}
             <h2 className="heading">
             Access the
            essential data you need to master flips <br /> in both primary and
             secondary markets
          </h2>

          <div className="btn-element">
            <a href="/" className="btn-main">
              Try For Free
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
