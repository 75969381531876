import React from "react";
import Navbar from "../components/Navbar";
import PriceBanner from "../components/Pricingbanner";
import Pricinglist from "../components/Pricinglist"
import Calltoaction from "../components/Calltoaction";
import Footer from "../components/Footer";


export default function Pricing() {
 
  return (
    
    <>
      <Navbar />
      <PriceBanner />
      <Pricinglist />
      <Calltoaction />
      <Footer />
    </>
  );
}
