import React from "react";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Counter from "../components/Counter";
import SliderLogos from "../components/SliderLogos";
import Features from "../components/Features";
import Calltoaction from "../components/Calltoaction";
import Footer from "../components/Footer";

import { menu_home_change } from "../Utils/custom";

export default function Home() {
  useEffect(() => {
    menu_home_change();
  }, []);


  return (
    <>
      <Navbar />
      <Banner />
      <Counter />
      <SliderLogos />
      <Features />
      <Calltoaction />
      <Footer />
    </>
  );
}
