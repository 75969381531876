import React from "react";
// import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <a href="/" className="footer-logo">
                <img src="assets/front/images/LogoWhite.svg" alt="footer" />
              </a>
              <div className="newsletter">
                <p>Sign Up For Our Newsletter</p>
                <div className="input-news">
                  <input
                    type="email"
                    className="form-control"
                    id=""
                    placeholder="Email"
                  />
                  <button className="btn">Submit</button>
                </div>
              </div>

              <div className="socials">
                <a href="/">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="/">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="/">
                  <i className="fa-brands fa-x-twitter"></i>
                </a>
                <a href="/">
                  <i className="fa-brands fa-youtube"></i>
                </a>
                <a href="/">
                  <i className="fa-brands fa-discord"></i>
                </a>
                <a href="/">
                  <i className="fa-brands fa-tiktok"></i>
                </a>
                <a href="/">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-sm-12"></div>
                <div className="col-lg-6 col-sm-12">
                  <div className="footer-links">
                    <h3>RESOURCES</h3>
                    <ul>
                      <li>
                        <a href="/">Blog</a>
                      </li>
                      <li>
                        <a href="/">Terms of Service</a>
                      </li>
                      <li>
                        <a href="/">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/">Become a Ticketmetric Influencer</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="copyrights">
            <ul>
              <li>© 2024 ticketmetric</li>
              <li>
                <a href="/">Terms of Use</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}
