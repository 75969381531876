import React from "react";
import { useEffect } from "react";
import { featureMenuChange, tabFeaturebuttons } from "../Utils/custom";
// import { Link } from "react-router-dom";

export default function Features() {
  useEffect(() => {
    featureMenuChange();
    tabFeaturebuttons();

  }, []);
  return (
    <>
      <section className="sec-features" id="features">
        <div className="container">
          <h2 className="main-heading-blue" data-aos="fade-up">
            Features
          </h2>
        </div>
      </section>
      <div className="tab-areaa">
        <div className="sticky-top sticky-ul">
          <ul
            className="nav nav-tabs tab-feature"
            id="tab-feature"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link "
                id="UpcomingSales-tab"
                data-bs-toggle="tab"
                data-bs-target="#UpcomingSales"
                type="button"
                role="tab"
                aria-controls="UpcomingSales"
                aria-selected="true"
              >
                Upcoming Sales
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="EventAnalytics-tab"
                data-bs-toggle="tab"
                data-bs-target="#EventAnalytics"
                type="button"
                role="tab"
                aria-controls="EventAnalytics"
                aria-selected="false"
              >
                Event Analytics
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Drop-tab"
                data-bs-toggle="tab"
                data-bs-target="#Drop"
                type="button"
                role="tab"
                aria-controls="Drop"
                aria-selected="false"
              >
                Drop, Price & low inventory alerts
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="ArtistMetric-tab"
                data-bs-toggle="tab"
                data-bs-target="#ArtistMetric"
                type="button"
                role="tab"
                aria-controls="ArtistMetric"
                aria-selected="false"
              >
                Artist Metric
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Extension-tab"
                data-bs-toggle="tab"
                data-bs-target="#Extension"
                type="button"
                role="tab"
                aria-controls="Extension"
                aria-selected="false"
              >
                Extension & Tools
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Community-tab"
                data-bs-toggle="tab"
                data-bs-target="#Community"
                type="button"
                role="tab"
                aria-controls="Community"
                aria-selected="false"
              >
                Community
              </button>
            </li>
          </ul>

          <select
            className="form-select"
            id="tab-munu-select"
            aria-label=""
 >
            <option value="1">Upcoming Sales</option>
            <option value="2" selected>
              Event Analytics
            </option>
            <option value="3">Drop, Price & Low Inventory Alerts</option>
            <option value="4">Artist Metric</option>
            <option value="5">Extension & Tools</option>
            <option value="6">Community</option>
          </select>
        </div>

        <div
          className="tab-content tab-feature-content"
          id="tab-featureContent"
        >
          <div
            className="tab-pane fade "
            id="UpcomingSales"
            role="tabpanel"
            aria-labelledby="UpcomingSales-tab"
          >
            <section className="sold-data">
              <div className="container-fluid p-0">
                <div className="sold-data-box">
                  <div className="left">
                    <div
                      className="card"
                      data-aos="fade-right"
                      data-aos-duration="3000"
                    >
                      <h3 data-aos="fade-up" data-aos-duration="3000">
                        Upcoming Sales{" "}
                      </h3>
                      <p>
                        Explore our comprehensive list of newly announced
                        presales and events on sale. Our extensive database
                        ensures you have all the information you need, including
                        presale codes for most events, right at your fingertips.
                      </p>

                      <ul>
                        <li>
                          <i className="fa-solid fa-circle"></i>Quick stats and
                          rankings for each artist.
                        </li>
                        <li>
                          <i className="fa-solid fa-circle"></i>Access presales
                          from hundreds of websites including: Ticketmaster,
                          AXS, Ticketweb, Etix, and many more
                        </li>
                        <li>
                          <i className="fa-solid fa-circle"></i>Detailed
                          capacity information for each venue.
                        </li>
                        <li>
                          <i className="fa-solid fa-circle"></i>Add events to
                          your buying list.
                        </li>
                        <li>
                          <i className="fa-solid fa-circle"></i>Make notes for
                          easy reference.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="right">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="3000"
                      className="tablet"
                      src="assets/front/images/sales/right.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            className="tab-pane fade show active"
            id="EventAnalytics"
            role="tabpanel"
            aria-labelledby="EventAnalytics-tab"
          >
            <section className="tab-sec-1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-sm-12">
                    <h2 className="main-heading-blue">Event Analytics</h2>
                    <h3 className="sub-heading">Search Engine</h3>
                    <p className="main-para width-90">
                      Experience the unmatched power of our Event Analytics
                      Search Engine,
                      <span className="bl-gr-bg">
                        {" "}
                        the first of its kind in the market.
                      </span>{" "}
                      Simply enter an artist or venue name to receive a
                      comprehensive performance snapshot across all events on
                      Ticketmaster, AXS, and StubHub.
                    </p>

                    <div className="list-feature">
                      <h3>Key details include:</h3>
                      <ul>
                        <li>Artist Rank</li>
                        <li>Venue Capacity</li>
                        <li>Available Primary and Secondary Tickets</li>
                        <li>StubHub Entry Prices</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-7 col-sm-12 moving-images">
                    <img
                      className="back js-tilt"
                      loading="lazy"
                      src="assets/front/images/features/1.svg"
                      alt=""
                    />
                    <img
                      className="front js-tilt"
                      loading="lazy"
                      src="assets/front/images/features/2.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="sec-primary-analytics">
              <div className="container">
                <div className="section-bg">
                  <div
                    className="left"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <div className="image-sec">
                      <img
                        className="pri-1 js-tilt"
                        loading="lazy"
                        src="assets/front/images/primary/1.svg"
                        alt=""
                      />
                      <img
                        className="pri-2 js-tilt"
                        loading="lazy"
                        src="assets/front/images/primary/2.svg"
                        alt=""
                      />
                      <img
                        className="pri-3 js-tilt"
                        loading="lazy"
                        src="assets/front/images/primary/3.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="heading">
                      <h2
                        className="main-heading-blue"
                        data-aos="fade-down"
                        data-aos-duration="3000"
                      >
                        Primary Analytics
                      </h2>
                    </div>
                    <div
                      className="right-content"
                      data-aos="fade-left"
                      data-aos-duration="3000"
                    >
                      <p className="main-para mb-4">
                        Ticketmetric's primary analytics dashboard offers access
                        to over 55,000 primary market events from Ticketmaster
                        and AXS, all tracked by Ticketmetric.
                        <img
                          loading="lazy"
                          src="assets/front/images/primary/arroww.png"
                          alt=""
                        />
                      </p>
                      <p className="main-para mb-4">
                        Simply pull up an event <br /> or venue, and all the
                        <br /> data you need is there.
                      </p>

                      <h3>Key Data Included</h3>

                      <ul>
                        <li>
                          <i className="fa-solid fa-circle-check"></i>Historical
                          Data for Entire Event
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check"></i>Historical
                          Data by Section
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check"></i>Original
                          Primary Prices
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check"></i>Live Data
                          Fetching
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check"></i>Add to
                          your Favorites
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="sec-secondary">
              <div className="container">
                <div className="sec-secondary-box">
                  <div className="left">
                    <h2 className="main-heading-blue m-auto" data-aos="fade-in">
                      Secondary Analytics
                    </h2>
                    <div className="images">
                      <img
                        loading="lazy"
                        data-aos="fade-in"
                        className="front-left"
                        src="assets/front/images/secondary/TextBoxAnimation1.svg"
                        alt=""
                      />
                      <img
                        loading="lazy"
                        data-aos="flip-up"
                        className="back "
                        src="assets/front/images/secondary/laptopp.svg"
                        alt=""
                      />
                      <img
                        loading="lazy"
                        data-aos="fade-in"
                        className="front-right"
                        src="assets/front/images/secondary/TextBoxAnimation2.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="right" data-aos="fade-left">
                    <h2 className="main-heading-blue">
                      Advanced Data and <br /> Charting Tools
                    </h2>

                    <p className="main-para">
                      Leverage top-tier secondary analytics.
                    </p>
                    <ul>
                      <li>
                        <i className="fa-solid fa-circle"></i>
                        Active Tickets Graph
                      </li>
                      <li>
                        <i className="fa-solid fa-circle"></i>
                        Average Price Graph
                      </li>
                      <li>
                        <i className="fa-solid fa-circle"></i>
                        Entry Price Graph
                      </li>
                      <li>
                        <i className="fa-solid fa-circle"></i>
                        Live data fetching
                      </li>
                      <li>
                        <i className="fa-solid fa-circle"></i>
                        Add to your favorites
                      </li>
                      <li>
                        <i className="fa-solid fa-circle"></i>
                        Directly integrate drop checkers <br />
                        from the dashboard
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className="sold-data">
              <div className="container-fluid p-0">
                <div className="sold-data-box">
                  <div className="left">
                    <div className="card" data-aos="fade-right">
                      <h3 data-aos="fade-up">Sold Data </h3>
                      <p>
                        Explore sales data for over 100K events across StubHub,
                        featuring 100% real transaction data with no delistings
                        or inaccuracies.
                      </p>
                      <h3 data-aos="fade-up">Sectional Insights</h3>
                      <p>
                        Ticketmetric delivers in-depth analytics tailored to
                        each section.
                      </p>
                      <ul>
                        <li>
                          <i className="fa-solid fa-circle"></i>Entrance,
                          average, and median prices
                        </li>
                        <li>
                          <i className="fa-solid fa-circle"></i>Comparison of
                          original primary prices with secondary entrance prices
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="right">
                    <img
                      loading="lazy"
                      data-aos="fade-left"
                      data-aos-duration="3000"
                      className="tablet"
                      src="assets/front/images/Soldata/solddata.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            className="tab-pane fade"
            id="Drop"
            role="tabpanel"
            aria-labelledby="Drop-tab"
          >
            <section className="sec-drop">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-sm-12">
                    <div className="bell-sec" data-aos="fade-up">
                      <div className="bell">
                        <img
                          className="bell"
                          src="assets/front/images/drop/Bell.svg"
                          alt=""
                        />
                      </div>
                      <h2 className="main-heading-blue">
                        Drop & Low
                        <br />
                        Alerts
                      </h2>
                    </div>
                    <div className="drop-content" data-aos="fade-right">
                      <h3>Drop Checker and Low Inventory Alerts</h3>
                      <ul>
                        <li>
                          <i className="fa-solid fa-circle"></i>
                          Set Alerts for hot events on Ticketmaster and AXS
                        </li>
                        <li>
                          <i className="fa-solid fa-circle"></i>
                          Get an Alert as soon as new ticket Drops or when the
                          quantity of tickets going under a set threshold
                        </li>
                      </ul>
                      <h3>Having access to these alerts</h3>
                      <ul>
                        <li>
                          <i className="fa-solid fa-circle"></i>
                          Provides a competitive edge in the market
                        </li>
                        <li>
                          <i className="fa-solid fa-circle"></i>
                          Allows you to catch opportunities to purchase high ROI
                          tickets
                        </li>
                      </ul>
                      <h3>Easy Set-Up</h3>
                      <ul>
                        <li>
                          <i className="fa-solid fa-circle"></i>
                          Alerts can be activated directly from the dashboard or
                          extension
                        </li>
                        <li>
                          <i className="fa-solid fa-circle"></i>
                          Can be sent to your email or Discord for maximum
                          convenience
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-7 col-sm-12 p-0" data-aos="fade-left">
                    <img
                      className="img-fluid drop-image js-tilt"
                      src="assets/front/images/drop/images.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            className="tab-pane fade"
            id="ArtistMetric"
            role="tabpanel"
            aria-labelledby="ArtistMetric-tab"
          >
            <section
              className="sec-artist"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="container">
                <h2
                  className="main-heading-blue mx-auto"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Browse Through Our Artist Search Engine
                </h2>

                <div
                  className="row"
                  data-aos="fade-left"
                  data-aos-duration="3000"
                >
                  <div className="col-lg-12 col-sm-12">
                    <img
                      className="img-fluid"
                      src="assets/front/images/artist/1.svg"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  className="artist-image-sec"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div className="left">
                    <h3>
                      <i className="fa-solid fa-circle"></i> Open an Artist
                      Profile
                    </h3>
                  </div>
                  <div className="right">
                    <img
                      className="img-fluid js-tilt"
                      src="assets/front/images/artist/2.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className="artist-image-sec"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div className="left">
                    <h3>
                      <i className="fa-solid fa-circle"></i> Grab In-Depth
                      Audience <br /> Location Data
                    </h3>
                  </div>
                  <div className="right">
                    <img
                      className="img-fluid js-tilt"
                      src="assets/front/images/artist/3.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className="artist-image-sec"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div className="left">
                    <h3>
                      <i className="fa-solid fa-circle"></i>Track Trends and
                      Other Data
                    </h3>
                  </div>
                  <div className="right">
                    <img
                      className="img-fluid js-tilt"
                      src="assets/front/images/artist/4.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className="artist-image-sec"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div className="left">
                    <h3>
                      <i className="fa-solid fa-circle"></i>Don't Miss Any
                      Undercover Hot <br /> Events
                    </h3>
                  </div>
                  <div className="right">
                    <img
                      className="img-fluid js-tilt"
                      src="assets/front/images/artist/5.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            className="tab-pane fade"
            id="Extension"
            role="tabpanel"
            aria-labelledby="Extension-tab"
          >
            <section className="sec-extension" data-aos="fade-up">
              <div className="container">
                <h2 className="main-heading-blue mx-auto" data-aos="fade-up">
                  The Most Advanced Extension on the Market
                </h2>

                <div className="extension-card-row">
                  <div className="box" data-aos="fade-right">
                    <img
                      className="img-fluid"
                      src="assets/front/images/tools/1.svg"
                      alt=""
                    />
                    <p>
                      <b>Inventory Stock Checker</b> - Get a quick view of all
                      tickets available on the primary and secondary markets. We
                      support the following websites: Ticketmaster, AXS,
                      VividSeats, StubHub, SeatGeek, TickPick, and Gametime.
                    </p>
                  </div>
                  <div className="box" data-aos="fade-left">
                    <img
                      className="img-fluid"
                      src="assets/front/images/tools/2.svg"
                      alt=""
                    />
                    <p>
                      <b>Historical Data</b> - The Ticketmetrics extension
                      provides comprehensive historical data for TM and AXS
                      events from day one. Access this feature while viewing
                      events on both primary and secondary markets, all within
                      the extension.
                    </p>
                  </div>
                  <div className="box" data-aos="fade-right">
                    <img
                      className="img-fluid"
                      src="assets/front/images/tools/3.svg"
                      alt=""
                    />
                    <p>
                      <b>Detailed StubHub Data</b> - With the Ticketmetrics
                      extension, you can access extensive historical data for
                      StubHub events. This feature includes average and minimum
                      entry price graphs, alongside available tickets, all while
                      browsing events on both primary and secondary markets.
                    </p>
                  </div>
                  <div className="box" data-aos="fade-left">
                    <img
                      className="img-fluid"
                      src="assets/front/images/tools/4.svg"
                      alt=""
                    />
                    <p>
                      <b>Drop Checker Alerts</b> - Activate our drop checker and
                      low inventory alerts for TM and AXS directly from the
                      extension to stay updated on the latest ticket drops.
                    </p>
                  </div>

                  <div className="box" data-aos="fade-right">
                    <img
                      className="img-fluid"
                      src="assets/front/images/tools/5.svg"
                      alt=""
                    />
                  </div>
                  <div className="box" data-aos="fade-left">
                   
                      <h3>Price Map Shader</h3>
                      <p>
                        This extension enhances your ability to spot price
                        breaks using color-coded visualization. It's an
                        invaluable tool for quickly identifying the highest ROI
                        tickets, particularly useful for presales
                      </p>
                 
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            className="tab-pane fade"
            id="Community"
            role="tabpanel"
            aria-labelledby="Community-tab"
          >
            <section className="container-fluid p-0">
              <div className="sec-community">
                <div className="left">
                  <div className="sub-cat">
                    <div className="bell-sec" data-aos="fade-up">
                      <div className="bell">
                        <img
                          className="bell"
                          src="assets/front/images/community/1.svg"
                          alt=""
                        />
                      </div>
                      <h2 className="main-heading-blue">Join Our Community</h2>
                    </div>
                    <div className="drop-content" data-aos="fade-right">
                      <h3>Join our exclusive Discord group</h3>
                      <ul>
                        <li>
                          <i className="fa-solid fa-circle"></i>A place to meet
                          and network with brokers who share your interests.
                        </li>
                      </ul>
                      <h3>Engage in Valuable Discussions</h3>
                      <ul>
                        <li>
                          <i className="fa-solid fa-circle"></i>
                          Participate in industry in-depth conversations and
                          discuss strategies.
                        </li>
                      </ul>
                      <h3>Motivate Each Other</h3>
                      <ul>
                        <li>
                          <i className="fa-solid fa-circle"></i>
                          Encourage and support fellow brokers in their journey
                        </li>
                      </ul>

                      <h3>Stay Updated with the Latest News</h3>
                      <ul>
                        <li>
                          <i className="fa-solid fa-circle"></i>
                          Keep up-to-date with the latest market trends and
                          opportunities.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right" data-aos="fade-left">
                  <img
                    className="img-fluid"
                    src="assets/front/images/community/2.svg"
                    alt=""
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
