import React from "react";
import { useEffect } from "react";
import { calculatePrice } from "../Utils/custom.js";
export default function Pricinglist() {

    useEffect(() => {
        calculatePrice();
      }, []);

  return (
    <>
 <section className="pricing">
        <div className="container">
            <div className="pricing-row">
                <div className="left" data-aos="fade-right" data-aos-duration="3000">
                    <h2 className="main-heading-blue">
                        Pick a plan that's <br/>
                        right for you <img className="line" src="assets/front/images/pricing/line.svg" alt="" />
                    </h2>
                    <p className="main-para">
                        Pricing plans for ticket brokers at every stage of <br/> growth.
                    </p>
                </div>
                <div className="right" data-aos="fade-left" data-aos-duration="3000">
                    <div className="btn-group price-btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input value="" type="radio" className="btn-check" name="btnradio" id="pricemonth"  checked="checked"/>
                        <label className="btn btn-outline-primary month active" htmlFor="pricemonth">Monthly</label>

                        <input value="" type="radio" className="btn-check" name="btnradio" id="priceyear" />
                        <label className="btn btn-outline-primary year" htmlFor="priceyear">Yearly <span className="badge">Save 20%
                                </span></label>
                    </div>
                </div>
            </div>
        </div>

        <div className="price-list-row ">
            <div className="price-box" data-aos="zoom-in" data-aos-duration="3000" >
                <h3 className="price-cat">
                    basic
                </h3>
                <p>
                    Ideal for newer brokers who want to start with the basics.
                </p>
                <hr/>

                <h2 className="price ">$<b className="price-monthly">99</b><b className="price-yearly d-none">79</b></h2>
            
                <span>Per member, per Month</span>
                <hr/>

                <ul className="price-listing">
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Upcoming Sales Dashboard
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Presale Codes
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Hot Event Watchlist
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Add to Personal Buying list
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Artist Quick Stats Popups
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-xmark not"></i>
                        Full Artist Metrics
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-xmark not"></i>
                        Sold Data
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-xmark not"></i>
                        Historical Primary Analytics
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-xmark not"></i>
                        Historical Secondary Analytics
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Extension - Stock Checker <span className="badge-success">Unlimited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Extension - Price Map Shader
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-xmark not"></i>
                        Extension - Historical Primary
                        and Secondary Analytics
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-xmark not"></i>
                        Drop, Low and Price Alerts
                    </li>
                </ul>

                <div className="btn-wrapper">
                    <a href="/">
                        Free Beta Trial
                    </a>
                    <span>No credit card required</span>
                </div>
            </div>
            <div className="price-box" data-aos="zoom-in" data-aos-duration="3000"  >
                <h3 className="price-cat">
                    ADVANCED
                </h3>
                <p>
                    For all advanced brokers ready to
                    up their game through analytics
                </p>
                <hr/>


                <h2 className="price ">$<b className="price-monthly">189</b><b className="price-yearly d-none">151</b></h2>
              
                <span>Per member, per Month</span>
                <hr/>

                <ul className="price-listing">
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Upcoming Sales Dashboard
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Presale Codes
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Hot Event Watchlist
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Add to Personal Buying list
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Artist Quick Stats Popups
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Full Artist Metrics
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Full Artist Metrics
                        <span className="badge-success">Unlimited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-xmark not"></i>
                        100 events Sold Data
                        <span className="badge-danger">limited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-xmark not"></i>
                        100 Historical Primary
                        & Secondary Analytics
                        <span className="badge-danger">limited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Extension - Stock Checker
                        <span className="badge-success">Unlimited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Extension - Price Map Shader
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-xmark not"></i>
                        Extension - Historical Primary
                        and Secondary Analytics
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        100 Ticketmaster Alerts
                        <span className="badge-danger">limited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        25 AXS Alerts
                        <span className="badge-danger">limited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        170 My Primary & Secondary Favorites
                        <span className="badge-danger">limited</span>
                    </li>
                </ul>

                <div className="btn-wrapper">
                    <a href="/">
                        Free Beta Trial
                    </a>
                    <span>No credit card required</span>
                </div>
            </div>
            <div className="price-box pro" data-aos="zoom-in" data-aos-duration="3000">
              
              <div className="professional-badges">
              <h3 className="price-cat">
                    PROFESSIONAL
                </h3>
                <span className="badge-popular">
                    Popular
                </span>
              </div>
              
                <p>
                For professionals ready to dominate the secondary market
                </p>
                <hr/>

                <h2 className="price ">$<b className="price-monthly">249</b><b className="price-yearly d-none">199</b></h2>

                <span>Per member, per Month</span>
                <hr/>

                <ul className="price-listing">
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Access to All Lower Tier Features
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        All Sold Data
                        <span className="badge-success">Unlimited</span>
                    </li>

                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        All Historical Primary
                        & Secondary Analytics
                        <span className="badge-success">Unlimited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        200 Ticketmaster Drop Alerts

                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        300 Ticketmaster Inventory Alerts
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        40 AXS Alerts
                    </li>

                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        600 My Primary & Secondary Favorites
                        <span className="badge-danger">limited</span>
                    </li>
                </ul>

                <div className="btn-wrapper">
                    <a href="/">
                        Free Beta Trial
                    </a>
                    <span>No credit card required</span>
                </div>
            </div>

            <div className="price-box" data-aos="zoom-in" data-aos-duration="3000">
                <h3 className="price-cat">
                    Enterprise
                </h3>
                <p>
                For established brokers seeking comprehensive alerts and tracking
                </p>
                <hr/>

                <h2 className="price ">$<b className="price-monthly">374</b><b className="price-yearly d-none">299</b></h2>
           
                <span>Per member, per Month</span>
                <hr/>

                <ul className="price-listing">
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Access to All Lower Tier Features
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        All Sold Data
                        <span className="badge-success">Unlimited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        All Historical Primary
                        & Secondary Analytics
                        <span className="badge-success">Unlimited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        Extension - Historical, Primary
                        & Secondary Analytics
                        <span className="badge-success">Unlimited</span>
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        400 Ticketmaster Drop Alerts
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        700 Low Inventory Alerts
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        50 AXS Drop Alerts
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        50 AXS Low Inventory Alerts
                    </li>
                    <li>
                        <i className="fa-solid fa-circle-check"></i>
                        1200 My Primary & Secondary Favorites
                    </li>

                </ul>

                <div className="btn-wrapper">
                    <a href="/">
                        Free Beta Trial
                    </a>
                    <span>No credit card required</span>
                </div>
            </div>
        </div>

    </section>
    </>
  );
}
